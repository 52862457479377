<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="biaodansj">
        <div class="tit">
            <p class="fanhui" @click="fanhui">
                <img src="../assets/histry.png" alt="">
            </p>
            <ul>
                <li v-for="(i,index) in text" :key='index' @click="qiehuan(index)" :style="moren==index?'background:#EB493B;':''"><p class="yuan" :style="moren==index?'background:#fff;border:0.01rem solid #fff;color:#EB493B;':'border:0.01rem solid #fff;color:#fff;'">{{index+1}}</p>{{i}}<p class="fangkuai" v-if="moren==index"></p></li>
            </ul>
            <div>
                <p>取消</p>
                <p @click="dianji_baocun">保存</p>
            </div>
        </div>
        <div class="con">
            <shezhi class="shezhia" @onezhi='onezhi' v-if="moren==0"></shezhi>
            <biaodan v-if="moren==1"></biaodan>
            <!-- <Main
                :data="data"
                @threezhi="threezhi"
                v-if="moren==2"
            /> -->
            <!-- <setting v-show="moren==2" ref="my_three"></setting> -->
            <!-- <gaoji class="shezhia" @fourzhi='fourzhi' v-if="moren==2"></gaoji> -->
        </div>
    </div>
</template>

<script>
import shezhi from '../approvalmodal/shezhi'
import biaodan from '../approvalmodal/biaodan'
import gaoji from '../approvalmodal/gaojishezhi'
import setting from '../views/approval/setting'
import {insert_flow_info_public,replace_flow_form_info_private,update_flow_action_info,insert_flow_info,insert_flow_action_info,insert_flow_form_info,insert_flow_action_str} from '../api/api'
export default {
  name: "biaodansj",
  data() {
    return {
        text:["基础设计",'表单设计'],
        moren:'0',
        onezhi_a:'',
        fourzhi_a:'',
        threezhi_a:'',
    };
  },
  components:{shezhi,biaodan,setting,gaoji},
  created(){

  },
  mounted(){
  },
  watch:{
  },
//
    // beforeRouteLeave (to, from, next) {
    //     // 这里需要elementui的支持，如果使用其他界面组件自行替换即可
    //     this.$confirm('正在离开本页面，本页面内所有未保存数据都会丢失', '警告', {
    //         confirmButtonText: '确定',
    //         cancelButtonText: '取消',
    //         type: 'warning'
    //     }).then(() => {
    //     // 正常跳转
    //     next()
    //     })
    //     // .catch(() => {
    //     // // 如果取消跳转地址栏会变化，这时保持地址栏不变
    //     // window.history.go(1)
    //     // })
    // },
  methods:{
        qiehuan(index){
            this.moren=index
        },
        // threezhi (three_zhi) {
        //     // this.$emit('update:data', data)
        //     console.log(three_zhi)
        //     this.threezhi_a=three_zhi
        // },
        onezhi(one_zhi){
            this.onezhi_a=one_zhi
        },
        // fourzhi(four_zhi){
        //     this.fourzhi_a=four_zhi
        // },
        // 点击返回
        fanhui(){
            this.$router.push('/biaodangg')
        },
        // 保存
        dianji_baocun(){
            console.log(11)
            // drag.$emit('save')   //表单那里
            // console.log(this.onezhi_a)
            // console.log(this.fourzhi_a)
            if(this.$bendi_naqu_cunchu('mlbb_one_shezhi','暂时')!=undefined){
                    this.onezhi_a=this.$bendi_naqu_cunchu('mlbb_one_shezhi','暂时')
                }
            if(this.onezhi_a==''||this.onezhi_a.shenpi_mingcheng==''){
                this.moren=0;
                this.$message({
                    message: '基础设置还未填写完整',
                    type: 'warning'
                });
            }else{

                // if(this.$bendi_naqu_cunchu('mlbb_four_shezhi','暂时')!=undefined){
                //     this.fourzhi_a=this.$bendi_naqu_cunchu('mlbb_four_shezhi','暂时')
                // }
                    // if(this.fourzhi_a==''||this.fourzhi_a.shifou==''){
                    //     this.moren=2;
                    //     this.$message({
                    //         message: '高级设置还未填写完整',
                    //         type: 'warning'
                    //     });
                    // }
                    // else {
                        // let suiji=this.$func.randomstring(10)
                        let suiji=this.$bendi_naqu_cunchu('mlbb_shenpi_zhengyi','暂时')!=undefined&&this.$bendi_naqu_cunchu('mlbb_shenpi_zhengyi','暂时').flow_id!=undefined?this.$bendi_naqu_cunchu('mlbb_shenpi_zhengyi','暂时').flow_id:this.$func.randomstring(10)
                        // let shifou=''
                        // let yijian=''
                        // let zidong_tongguo=''
                        // let yijian_tianxie=''
                        let shei_keyi_guanli=''
                        let shenpi_shuoming=''
                        let shenpi_shuoming_name=''
                        let cunchu_data=this.$bendi_naqu_cunchu('mlbb_biaodan_sheji','暂时')
                        for(let sort=0;sort<cunchu_data.length;sort++){
                            cunchu_data[sort]['sort']=sort+''
                            if(cunchu_data[sort].components!=undefined){
                                for(let nei_sort=0;nei_sort<cunchu_data[sort].components.length;nei_sort++){
                                    cunchu_data[sort].components[nei_sort]['sort']=nei_sort
                                }
                            }
                        }
                        // let chuanshu_list=''
                        // let liucheng_list={
                        //     nodeConfig:null,
                        //     flowPermission:null,
                        //     tableId:null,
                        // }
                        // if(this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi','暂时')!=undefined){
                        //     liucheng_list.nodeConfig=this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi','暂时')
                        // }
                        // if(this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_flowPermission','暂时')!=undefined){
                        //     liucheng_list.flowPermission=this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_flowPermission','暂时')
                        // }
                        // if(this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_tableId','暂时')!=undefined){
                        //     liucheng_list.tableId=this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_tableId','暂时')
                        // }
                        let liucheng_list={
                            nodeConfig:[],
                            flowPermission:null,
                            tableId:null,
                            all_or_not:0,
                            faqi_fanwei_name:'所有人'
                        }
                        if(this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi','暂时')!=undefined){
                            liucheng_list.nodeConfig={"childNode":null,"conditionList":[],"conditionNodes":[],"nodeName":"发起人","nodeUserList":[],"type":"0","id":"Lq0mX5O4yL"}
                        }
                        liucheng_list.flowPermission=[]
                        // if(this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_list','暂时')!=undefined){
                        //     chuanshu_list=this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_list','暂时')
                        // }
                        // if(this.fourzhi_a.shifou==1){
                        //     shifou='0'
                        // }else if(this.fourzhi_a.shifou==0){
                        //     shifou='1'
                        // }
                        if(this.onezhi_a.shei_keyi_guanli_id==''){
                            shei_keyi_guanli=null
                            shenpi_shuoming_name=null
                        }else{
                            shei_keyi_guanli=this.onezhi_a.shei_keyi_guanli_id
                            shenpi_shuoming_name=this.onezhi_a.shei_keyi_guanli
                        }
                        if(this.onezhi_a.shenpi_shuoming==''){
                            shenpi_shuoming=null
                        }else{
                            shenpi_shuoming=this.onezhi_a.shenpi_shuoming
                        }
                        // if(this.fourzhi_a.shenpi_yijian==false){
                        //     yijian='0'
                        // }else if(this.fourzhi_a.shenpi_yijian==true){
                        //     yijian='1'
                        // }
                        // if(this.fourzhi_a.faqiren_zidong_tongguo==false){
                        //     zidong_tongguo='0'
                        // }else if(this.fourzhi_a.faqiren_zidong_tongguo==true){
                        //     zidong_tongguo='1'
                        // }
                        // if(this.fourzhi_a.yijian==''){
                        //     yijian_tianxie=null
                        // }else{
                        //     yijian_tianxie=this.fourzhi_a.yijian
                        // }
                        let table=''
                        if(suiji=='VMCizuVOXb'){
                            table='invoice'
                        }else if(suiji=='He5ln0bdDO'){
                            table='received_cash'
                        }else if(suiji=='qIwxmsMTgv'){
                            table='contract'
                        }else if(suiji=='Eb3n7CKQzi'){
                            table='s_dispatch'
                        }else if(suiji=='PLOQ9ku3Ip'){
                            table='funn'
                        }else if(suiji=='GBh7N2jMsi'){
                            table='s_biz_trip'
                        }else if(suiji=='cvRyWGiUQH'){
                            table='s_go_out'
                        }else if(suiji=='7TvJHPyovM'){
                            table='customer'
                        }else if(suiji=='BAw4JKWJgE'){
                            table='qIwxmsMTgv'
                        }else if(suiji=='Z7VDOK9HGu'){
                            table='s_visit_plan'
                        }else if(suiji=='sAuIdd1ii2'){
                            table='mc_topup'
                        }else if(suiji=='nCvUA7dkYc'){
                            table='mc_topup'
                        }else if(suiji=='453y6pLD52'){
                            table='s_visit_record'
                        }else if(suiji == "1RLq2GRAR0") {
                            table="mc_topup"
                        }
                        else if(suiji == "nCvUA7dkYc") {
                            table="mc_topup"
                        }else if(suiji=='H49d4edc3T'){
                            table='s_deliver'
                        }else if(suiji=='5FotYgnu0Z'){
                            table='supply'
                        }else if(suiji=='u1SCMMoUwK'){  //采购发票单
                            table='purchase_invoice'
                        }else if(suiji=='Xsk2pLBS9a'){
                            table='purchase'
                        }else if(suiji=='AY3cli7BAb'){ //vip充值单
                            table ='mc_topup'
                        }else if(suiji=='vY9ubhTO89'){  //付款单
                            table='payment'
                        }else if(suiji=='fZ0x5qedzn'){  //入库单
                            table='in_store'
                        }else if(suiji=='hXstgwxhzh'){  //出库单
                            table='out_store'
                        }
                         // 新增审批流程基础信息————公开
                        insert_flow_info_public({data:{
                            ent_id:'public',
                            flow_id:suiji,
                            flow_name:this.onezhi_a.shenpi_mingcheng,
                            flow_desc:shenpi_shuoming,
                            flow_group_id:'1',
                            rel_table:table,
                        }}).then(res=>{
                            console.log(res,'看看这是什么')
                            if(res.data.code==200){
                              // 新增审批表单设置str
                                insert_flow_form_info({data:{
                                    ent_id:'public',
                                    flow_id:suiji,
                                    list:JSON.stringify(cunchu_data)
                                }}).then(ras=>{
                                    if(ras.data.code==200){
                                        this.$message({
                                            message: '保存成功',
                                            type: 'success'
                                        });
                                        this.$router.push('/biaodangg')
                                        // update_flow_action_info({data:{
                                        //     ent_id:this.$ent_id(),
                                        //     flow_id:suiji,
                                        //     list:chuanshu_list,
                                        // }}).then(rbs=>{
                                        //     console.log(rbs)
                                        //     if(rbs.data.code==200){
                                        //         alert('111')
                                        //     }else{
                                        //     }
                                        // })
                                        // 加公共的没有意义       2020、10.21   小虎说的
                                        // insert_flow_action_str({data:{
                                        //     ent_id:'public',
                                        //     flow_id:suiji,
                                        //     action_str:JSON.stringify(liucheng_list),
                                        // }}).then(rds=>{
                                        //     if(rds.data.code==200){
                                        //         // alert('111')
                                        //         this.$message({
                                        //             message: '保存成功',
                                        //             type: 'success'
                                        //         });
                                        //         this.$router.push('/biaodangg')
                                        //     }else if(rds.data.code==500){}
                                        // })
                                    }else if(ras.data.code==500){}
                                })
                            }else if(res.data.code==500){}
                        })
                    // }
                }

            //     // 值({data:{

            //     // }}).then(res=>{
            //     //     console.log(res)
            //     //     if(res.data.code==200){

            //     //     }else if(res.data.code==500){}
            //     // })
            }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.biaodansj {
    background:#F4F7FD;
    min-height:100vh;
    .tit{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #F96C6C;
        padding: 0 0.29rem;
        height:0.54rem;
        .fanhui{
            height:100%;
            border-right:0.01rem solid #833838;
            display: flex;
            align-items: center;
            cursor: pointer;
            img{
                height: 32.0.05rem;
                // width:20.0.05rem;
                padding-right:0.2rem;
            }
        }
        ul{
            display: flex;
            align-items: center;
            height:0.54rem;
            margin-left: 1.4rem;
            li{
                height:100%;
                display: flex;
                align-items: center;
                padding: 0 0.59rem;
                font-size: 0.14rem;
                color:#fff;
                position: relative;
                cursor: pointer;
                .yuan{
                    height:0.18rem;
                    width:0.18rem;
                    border-radius: 50%;
                    font-size: 0.14rem;
                    line-height: 0.18rem;
                    text-align: center;
                    margin-right:0.06rem;
                }
                .fangkuai{
                    position: absolute;
                    height:0.14rem;
                    width:0.14rem;
                    transform:rotate(45deg);
                    -ms-transform:rotate(45deg); /* Internet Explorer */
                    -moz-transform:rotate(45deg); /* Firefox */
                    -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
                    -o-transform:rotate(45deg); /* Opera */
                    background:#E7E8EB;
                    bottom: -0.07rem;
                    left:50%;
                    margin-left: -0.07rem;
                }
            }
        }
        div{
            display: flex;
            align-items: center;
            p{
                padding: 0.12rem 0.27rem;
                font-size: 0.14rem;
                cursor: pointer;
                border:0.01rem solid #fff;
                &:nth-child(1){
                    color:#fff;
                    margin-right:0.11rem;
                }
                &:nth-child(2){
                    color:#F96C6C;
                    background: #fff;
                }
            }
        }
    }
    .con{
        .shezhia{
            margin: 0 auto;
            margin-top: 0.39rem;
        }
    }
}
@media screen and (max-width: 1220px) {
  .biaodansj {
    width:1220px;
  }
}
</style>
